<template>
    <div class="ranking_detail">
        <div class="mian">
            <div class="inner_nav clearfix">
                <div class="fl inner_nav_l">当前位置：<span><router-link to="/fontSearch">每日一字</router-link></span> > <router-link to="/fontSearch">字体搜索</router-link></div>
            </div>
        </div>
        <div class="mian">
            <div class="ranking_detail_info">
                <div class="ranking_detail_info_top clearfix">
                    <div class="fl detail_info_top_l">
                        <div class="detail_info_top_l_name">{{info.title}}</div>
                        <div class="detail_info_top_l_dec">上传时间：{{info.time}}  丨  作品已申请版权登记保护</div>
                    </div>
                    <div class="fr detail_info_top_r">
                        <div class="fl">分享到：</div>
                        <img class="fl" @click="$share.toQQ(info.title)" src="../assets/images/detailqq.png" alt="">
                        <img class="fl" @click="$share.toWeibo(info.title)" src="../assets/images/detailweibo.png" alt="">
                        <div class="fl wxShare">
                            <img src="../assets/images/detailweixin.png" alt="">
                            <div class="wxShare_box">
                                <div>使用微信 扫一扫</div>
                                <div id="qrcode" ref="qrcode"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ranking_detail_info_img">
                    <div style="text-align: center"><img :src="info.thumb" style="max-width: 100%"></div>
                    <div v-html="info.content"></div>
                    <!-- <img src="../assets/images/image03_mrtj2x.png" alt=""> -->
                </div>
            </div>
        </div>
        <!-- 侧边导航 -->
        <sidebar ref="downtotal"></sidebar>
    </div>
</template>

<script>
const wx = require('weixin-js-sdk')
import QRCode from 'qrcodejs2'
// import { WOW } from 'wowjs'
import sidebar from '../components/sidebar.vue'
export default {
    name: "fontSearchDetail",
    components:{
        sidebar
    },
    data(){
        return{
            info:{},//详情

            list:[],//最新上榜
        }
    },
    mounted(){
        this.$parent.routerIndex = 5;
        this.$parent.showIndex = 1;
        // new WOW().init();
        if(this.$route.query.id){
            this.initFun(this.$route.query.id)
        }

        this.$refs.downtotal.listFun()
    },
    methods:{
        initFun(id){
            var that = this;
            var params ={
                id:id,
            }
            this.$api.POST(this.$face.onlistSearchsInfo,params,function(res){
                that.info = res.data;
                that.info.time = that.timeFun(that.info.create_time)
                that.$nextTick(() => {
                    let qrcode = new QRCode('qrcode',{
                        width: 100, // 设置宽度，单位像素
                        height: 100, // 设置高度，单位像素
                        text: 'https://m.zitisheji.com/#/pages/fontsearch/uni_searchdet?id='+id // 设置二维码内容或跳转地址
                    })
                })
            })
        },
        //时间处理
        timeFun(el){
            var arry = el.substring(0,10).split('-');
            return arry.join('/')
        },

        // 查看详情
        toDetail(id){
            this.$router.push({name:'rankingDetail',query:{id:id}})
        },
    },
    watch:{
        
    }
};
</script>

<style scoped>
.ranking_detail{
    background-color: #f6f6f6;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}


.ranking_detail_info{
    width: 100%;
    background: #FFF;
    border-radius: 10px;
    padding: 0 31px 0 24px;
    box-sizing: border-box;
    min-height: 600px;
    margin-bottom: 59px;
}

.ranking_detail_info_top{
    border-bottom: 1px solid #eeeeee;
}
.detail_info_top_l{
    padding: 43px 0 26px;
}
.detail_info_top_l_name{
    font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #333333;
    margin-bottom: 18px;
}
.detail_info_top_l_dec{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #999999;
}
.detail_info_top_r{
    padding-top: 67px;
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
	color: #999999;
    
}
.detail_info_top_r img{
    margin: 0 6px;
}
.detail_info_top_r img:last-child{
    margin-right: 0;
}

.ranking_detail_info_img{
    padding: 33px 0 50px;
}
.ranking_detail_info_img >>> img{
    max-width: 1000px;
    margin: 0 auto 14px;
}


.sidebar_r{
    right: 2%;
    left: auto;
}
</style>